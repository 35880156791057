import * as Sentry from '@sentry/nextjs';

interface ExtraContext {
  [key: string]: any;
}

export const captureError = (error: any, context: ExtraContext = {}) => {
  Sentry.captureException(error, {
    extra: context,
  });
};
