import { Address } from 'viem';
import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

type State = {
  isAuthorized: boolean;
  openOnboard1CT: boolean;
  walletOI: number;
  openOnboardLeagues: boolean;
  supressIntroduceProfiles: Record<Address, boolean>;
};

type Action = {
  updateUserStore: (key: keyof State, value: any) => void;
  resetStore: () => void;
};

const initialState = {
  isAuthorized: false,
  openOnboard1CT: false,
  walletOI: 0,
  openOnboardLeagues: true,
  supressIntroduceProfiles: {},
};

type PersistedState = {
  openOnboardLeagues: boolean;
  supressIntroduceProfiles: Record<Address, boolean>;
};
type TradePersist = (
  config: StateCreator<State & Action>,
  options: PersistOptions<PersistedState>
) => StateCreator<State & Action>;

export const useUserStore = create<State & Action>(
  (persist as TradePersist)(
    (set) => ({
      ...initialState,
      updateUserStore: (key, value) => set(() => ({ [key]: value })),
      resetStore: () => set(() => initialState),
    }),
    {
      name: 'user-store',
      storage: createJSONStorage(() => localStorage),
      partialize: (state): PersistedState => ({
        openOnboardLeagues: state.openOnboardLeagues,
        supressIntroduceProfiles: state.supressIntroduceProfiles,
      }),
    }
  )
);
