import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/lib/utils';
const buttonVariants = cva('inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50', {
  variants: {
    variant: {
      outline: 'border border-grey-400 text-grey-200 hover:text-grey-000',
      ghost: 'bg-transparent border-0 text-grey-200 hover:text-grey-000',
      default: 'gradient-purple text-primary-foreground hover:bg-primary/90',
      navLink: 'bg-grey-800 hover:bg-grey-1200 text-grey-200 hover:text-grey-000 border-0 text-base ring-0',
      rewards: 'bg-grey-600 ring-0 focus-visible:ring-0 ring-offset-0 focus-visible:ring-offset-0',
      pairMenu: 'bg-grey-600 hover:bg-grey-1000 text-grey-200 hover:text-grey-000 rounded-full font-light',
      orderType: 'bg-grey-600 border-grey-400 border w-full focus-visible:ring-0 focus-visible:ring-offset-0 ring-0',
      collateral: 'bg-grey-500 hover:border-grey-400 text-grey-100 text-xs font-normal hover:bg-grey-600 hover:text-grey-000 focus-visible:ring-0 ring-offset-0 focus-visible:ring-offset-0',
      long: 'bg-primary-200 hover:bg-primary-200/80 text-grey-900 text-base font-medium focus-visible:ring-0 focus-visible:ring-offset-0 ring-0',
      short: 'bg-error-100 text-grey-900 hover:text-grey-000 text-base font-medium',
      tabs: 'border-r border-grey-500 px-10 py-4 flex hover:bg-grey-1000 cursor-pointer text-grey-200 hover:text-grey-000 rounded-none',
      actions: 'bg-grey-500 flex hover:bg-grey-1000 cursor-pointer text-grey-000 md:text-grey-200 hover:text-grey-000 rounded-md focus-visible:ring-0 focus-visible:ring-offset-0 ring-0',
      pills: 'bg-grey-600 hover:bg-grey-1000 text-grey-200 hover:text-grey-000 rounded-full text-sm font-normal',
      oneCT: 'bg-grey-500 rounded-md text-grey-000 text-purple-650 bg-[rgba(111,41,223)]/15'
    },
    size: {
      default: 'h-10 px-4 py-2',
      xxs: 'h-6 py-1 px-5',
      'xs-square': 'h-7 p-1.5',
      xs: 'h-7 px-2.5 py-1',
      sm: 'h-9 px-3 py-2',
      icon: 'h-10 w-10',
      lg: 'h-11 rounded-md px-8'
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default'
  }
});
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  ismobile?: string;
  // isMobile?: boolean
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  variant,
  size,
  asChild = false,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : 'button';
  return <Comp className={cn(buttonVariants({
    variant,
    size,
    className
  }))} ref={ref} {...props} />;
});
Button.displayName = 'Button';
export { Button, buttonVariants };