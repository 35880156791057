import pairsdata from '../../pairData.json';

export enum PairNameToPairId {
  'ETH/USD' = 0,
  'BTC/USD' = 1,
  'SOL/USD' = 2,
  'BNB/USD' = 3,
  'ARB/USD' = 4,
  'DOGE/USD' = 5,
  'AVAX/USD' = 6,
  'OP/USD' = 7,
  'POL/USD' = 8,
  'TIA/USD' = 9,
  'SEI/USD' = 10,
  'EUR/USD' = 11,
  'JPY/USD' = 12,
  'GBP/USD' = 13,
  'CAD/USD' = 14,
  'CHF/USD' = 15,
  'SEK/USD' = 16,
  'AUD/USD' = 17,
  'NZD/USD' = 18,
  'SGD/USD' = 19,
  'XAG/USD' = 20,
  'XAU/USD' = 21,
  'SHIB/USD' = 22,
  'PEPE/USD' = 23,
  'BONK/USD' = 24,
  'WIF/USD' = 25,
  'RENDER/USD' = 26,
  'WLD/USD' = 27,
  'FET/USD' = 28,
  'ARKM/USD' = 29,
  'PENDLE/USD' = 30,
  'ONDO/USD' = 31,
  'PRIME/USD' = 32,
  'DYM/USD' = 33,
  'ORDI/USD' = 34,
  'STX/USD' = 35,
  'ENA/USD' = 36,
  'AERO/USD' = 37,
  'ETHFI/USD' = 38,
  'JUP/USD' = 39,
  'REZ/USD' = 40,
  'LINK/USD' = 41,
  'LDO/USD' = 42,
  'NEAR/USD' = 43,
  'INJ/USD' = 44,
  'ZK/USD' = 45,
  'ZRO/USD' = 46,
  'BLAST/USD' = 47,
  'AAVE/USD' = 48,
  'SUI/USD' = 49,
  'TAO/USD' = 50,
  'EIGEN/USD' = 51,
}

export const pairNames: {
  [key: string]: string;
} = {
  'BTC/USD': 'Bitcoin',
  'ETH/USD': 'Ethereum',
  'SOL/USD': 'Solana',
  'AVAX/USD': 'Avalanche',
  'BNB/USD': 'Binance Smart Chain',
  'ARB/USD': 'Arbitrum',
  'OP/USD': 'Optimism',
  'POL/USD': 'Polygon',
  'DOGE/USD': 'Dogecoin',
  'TIA/USD': 'Celestia',
  'SHIB/USD': 'Shiba Inu',
  'BONK/USD': 'Bonk',
  'PEPE/USD': 'Pepe',
  'WIF/USD': 'dogwifhat',
  'SEI/USD': 'Sei',
  'RENDER/USD': 'Render',
  'WLD/USD': 'Worldcoin',
  'ARKM/USD': 'Arkham',
  'PENDLE/USD': 'Pendle',
  'ONDO/USD': 'Ondo',
  'FET/USD': 'Fetch.ai',
  'PRIME/USD': 'Echelon Prime',
  'DYM/USD': 'Dymension',
  'ORDI/USD': 'Ordi',
  'STX/USD': 'Stacks',
  'ENA/USD': 'Ethena',
  'EUR/USD': 'Euro / US dollar',
  'GBP/USD': 'British pound / US dollar',
  'USD/JPY': 'US dollar / Japanese yen',
  'USD/CAD': 'US dollar / CA dollar',
  'USD/CHF': 'US dollar / Swiss franc',
  'USD/SEK': 'US dollar / SE Krona',
  'AUD/USD': 'AU dollar / US dollar',
  'NZD/USD': 'NZ dollar / US dollar',
  'USD/SGD': 'US dollar / SG dollar',
  'XAG/USD': 'Silver / US dollar',
  'XAU/USD': 'Gold / US dollar',
  'AERO/USD': 'Aerodrome',
  'ETHFI/USD': 'Ether.fi',
  'JUP/USD': 'Jupiter',
  'REZ/USD': 'Renzo',
  'LINK/USD': 'Chainlink',
  'NEAR/USD': 'Near',
  'LDO/USD': 'Lido Finance',
  'AAVE/USD': 'Aave',
  'SUI/USD': 'Sui',
  'BLAST/USD': 'Blast',
  'ZK/USD': 'ZKsync',
  'ZRO/USD': 'Layer Zero',
  'TAO/USD': 'Tao Bittensor',
  'EIGEN/USD': 'Eigen Layer',
  'INJ/USD': 'Injective',
};

export const zeroSlippagePairs = [PairNameToPairId['BTC/USD'], PairNameToPairId['JPY/USD']];

export const groupEnum = ['Crypto', 'Crypto', 'FX', 'Metal', 'Crypto', 'Crypto'];
export type TPair = (typeof pairsdata)['pairs'][0];

const preLaunchPairs = [PairNameToPairId['BLAST/USD']];
const delistedPairs = [PairNameToPairId['FET/USD'], PairNameToPairId['PRIME/USD']];

export const filteredPairs = pairsdata.pairs
  .filter((pair) => ![...preLaunchPairs, ...delistedPairs].includes(pair._index))
  .filter((pair) => !pair.isDelisted)
  .filter((pair) => pairNames[`${pair.from}/${pair.to}`]);

export const groupIndexToMaxLeveragePair: Record<number, number> = {
  0: 100,
  1: 40,
  2: 100,
  3: 50,
  4: 25,
  5: 10,
};

export enum PairMenuTabs {
  Favourites = 'favourites',
  All = 'all',
  Commodities = 'commodities',
  Forex = 'forex',
  BtcEco = 'btc-eco',
  Defi = 'defi',
  AI = 'ai',
  Memes = 'memes',
  L1L2 = 'l1-l2',
  Crypto = 'crypto',
  Restaking = 'restaking',
  Search = 'search',
}

type IPairMenuTabsInfo = {
  display: string;
  pairMenuType: PairMenuTabs;
  groupIndex?: number;
  pairs?: PairNameToPairId[];
};

export const pairMenuConfig: Record<string, IPairMenuTabsInfo> = {
  [PairMenuTabs.Crypto]: {
    display: 'Blue Chip Crypto',
    pairMenuType: PairMenuTabs.Crypto,
    pairs: [PairNameToPairId['ETH/USD'], PairNameToPairId['BTC/USD'], PairNameToPairId['SOL/USD']],
  },
  [PairMenuTabs.Memes]: {
    display: 'Memes',
    pairMenuType: PairMenuTabs.Memes,
    pairs: [
      PairNameToPairId['DOGE/USD'],
      PairNameToPairId['SHIB/USD'],
      PairNameToPairId['PEPE/USD'],
      PairNameToPairId['BONK/USD'],
      PairNameToPairId['WIF/USD'],
    ],
  },
  [PairMenuTabs.L1L2]: {
    display: 'L1s / L2s',
    pairMenuType: PairMenuTabs.L1L2,
    pairs: [
      PairNameToPairId['POL/USD'],
      PairNameToPairId['AVAX/USD'],
      PairNameToPairId['ARB/USD'],
      PairNameToPairId['OP/USD'],
      PairNameToPairId['BNB/USD'],
      PairNameToPairId['TIA/USD'],
      PairNameToPairId['SEI/USD'],
      PairNameToPairId['DYM/USD'],
      PairNameToPairId['NEAR/USD'],
      PairNameToPairId['INJ/USD'],
      PairNameToPairId['SUI/USD'],
      PairNameToPairId['ZK/USD'],
      // PairNameToPairId['BLAST/USD'],
    ],
  },
  [PairMenuTabs.AI]: {
    display: 'AI',
    pairMenuType: PairMenuTabs.AI,
    pairs: [
      PairNameToPairId['RENDER/USD'],
      PairNameToPairId['WLD/USD'],
      PairNameToPairId['ARKM/USD'],
      PairNameToPairId['TAO/USD'],
    ],
  },
  [PairMenuTabs.Defi]: {
    display: 'DeFi',
    pairMenuType: PairMenuTabs.Defi,
    pairs: [
      PairNameToPairId['AERO/USD'],
      PairNameToPairId['ENA/USD'],
      PairNameToPairId['JUP/USD'],
      PairNameToPairId['ONDO/USD'],
      PairNameToPairId['PENDLE/USD'],
      PairNameToPairId['LDO/USD'],
      PairNameToPairId['LINK/USD'],
      PairNameToPairId['AAVE/USD'],
      PairNameToPairId['ZRO/USD'],
    ],
  },
  [PairMenuTabs.BtcEco]: {
    display: 'Bitcoin Eco',
    pairMenuType: PairMenuTabs.BtcEco,
    pairs: [PairNameToPairId['STX/USD'], PairNameToPairId['ORDI/USD']],
  },
  [PairMenuTabs.Forex]: {
    display: 'Forex',
    pairMenuType: PairMenuTabs.Forex,
    groupIndex: 2,
  },
  [PairMenuTabs.Commodities]: {
    display: 'Commodities',
    pairMenuType: PairMenuTabs.Commodities,
    groupIndex: 3,
  },
  [PairMenuTabs.Restaking]: {
    display: 'Restaking',
    pairMenuType: PairMenuTabs.Restaking,
    pairs: [
      PairNameToPairId['ETHFI/USD'],
      PairNameToPairId['REZ/USD'],
      PairNameToPairId['EIGEN/USD'],
    ],
  },
};

export const pairMenuTabs = [
  PairMenuTabs.Crypto,
  PairMenuTabs.Memes,
  PairMenuTabs.L1L2,
  PairMenuTabs.AI,
  PairMenuTabs.Defi,
  PairMenuTabs.BtcEco,
  PairMenuTabs.Forex,
  PairMenuTabs.Commodities,
  PairMenuTabs.Restaking,
];

export type Side = 'Long' | 'Short' | 'N/A' | '-';
