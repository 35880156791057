'use client';
import { useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAccount } from 'wagmi';
import useAppToast from '../shared/useAppToast';
import { Address } from 'viem';
import { League } from '@/components/leagues/types';

class SocketManager {
  private static instance: SocketManager | null = null;
  private socket: Socket | null = null;

  private constructor(baseUrl: string, path: string) {
    this.socket = io(baseUrl, {
      path,
      transports: ['websocket', 'polling'],
      autoConnect: true,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 0,
      reconnectionDelayMax: 0,
      randomizationFactor: 0,
    });
  }

  public static getInstance(baseUrl: string, path: string): SocketManager {
    if (SocketManager.instance === null) {
      SocketManager.instance = new SocketManager(baseUrl, path);
    }
    return SocketManager.instance;
  }

  public getSocket(): Socket {
    if (this.socket === null) {
      throw new Error('Socket connection is not initialized');
    }
    return this.socket;
  }
}

const notifSocket = SocketManager.getInstance(
  'https://notification.avantisfi.com/notification',
  '/socket.io'
);

interface INotif {
  _id: 'string';
  address: 'string';
  group: 'string';
  name: 'string';
  data: {
    address: Address;
    oldLevel: League;
    newLevel: League;
    isLevelUp: boolean;
  };
  type: 'success' | 'error' | 'info';
  read: 'boolean';
  readAt: 'string';
  createdAt: 'string';
}

const useNotifSocketUpdates = () => {
  const { address } = useAccount();
  const { leagueDemotion, leaguePromotion } = useAppToast();

  useEffect(() => {
    notifSocket.getSocket().on('connect', () => {
      console.log('Connected to Notification websocket');
      notifSocket.getSocket().emit('subscribeToAddress', {
        address,
      });
    });

    if (address) {
      // for picking notifs that wasn't shown since user was offline
      fetch(`https://notification.avantisfi.com/notifications/${address}/1`).then((notifs) => {
        notifs.json().then((data: INotif[]) => {
          if (data && data[0] && !data[0].read) {
            if (data[0].data.isLevelUp) {
              leaguePromotion({ league: data[0].data.newLevel, notifId: data[0]._id });
            } else {
              leagueDemotion({ league: data[0].data.newLevel, notifId: data[0]._id });
            }
          }
        });
      });
    }

    notifSocket.getSocket().on('notification', (data: INotif) => {
      if (data.data.isLevelUp) {
        leaguePromotion({ league: data.data.newLevel, notifId: data._id });
      } else {
        leagueDemotion({ league: data.data.newLevel, notifId: data._id });
      }
    });

    return () => {
      notifSocket.getSocket().off();
    };
  }, [address]);
};

export const markNotifRead = ({ notifId }: { notifId: string }) => {
  notifSocket.getSocket().emit('readReceipt', {
    _id: notifId,
  });
};

export default useNotifSocketUpdates;
