export const HERMES_URL = process.env.NEXT_PUBLIC_HERMES_URL as string;
export const DEDICATED_RPC = process.env.NEXT_PUBLIC_DEDICATED_RPC as string;
export const PUBLIC_RPC = process.env.NEXT_PUBLIC_PUBLIC_RPC_PRIMARY as string;
export const PUBLIC_RPC_SECONDARY = process.env.NEXT_PUBLIC_PUBLIC_RPC_SECONDARY as string;
export const PROJECT_ID = process.env.NEXT_PUBLIC_PROJECT_ID as string;
export const API_URL = process.env.NEXT_PUBLIC_APP_API as string;
export const SOCKET_URL = process.env.NEXT_PUBLIC_SOCKET_URL as string;
export const SOCKET_API_URL = SOCKET_URL + '/v1';
export const FEED_URL = process.env.NEXT_PUBLIC_FEED_APP as string;
export const ENV = process.env.NEXT_PUBLIC_ENV as string;
export const DISABLE_AUTH_CHECK = ENV === 'development';
export const IS_BETA = (process.env.NEXT_PUBLIC_IS_BETA as string) === 'true';
export const LEAGUES_IMAGE_PREFIX = process.env.NEXT_PUBLIC_IMAGE_PREFIX as string;
